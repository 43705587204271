<template>
  <div style="height: 100%;">
    <!-- 先通过接口判断当前节点是什么状态, -1一定是在我的空间下创建文章
    需要接口返回：
    当前这个节点是否hasArticle = 1 认为是编辑文章（还需判断请求人是否是作者，不是则为查看，协作人后期处理）；
    =0 认为是在该节点下新增文章（还需判断该节点是否是请求人创建的，不是则不能创建，协作人后期处理）
    如果是存在dzz的文件，则应该是到dzz的文件展示界面（或者先不处理？）
     -->
     <!-- 最新逻辑，点击新增文章，会先生成节点再跳转到新增界面，所以进到/wiki/article界面只会是编辑（文章是自己的）或者查看 -->
    <div v-if="noData && !isLoading">
      <div style="text-align:center;line-height:300px;color:#444">查不到数据</div>
    </div>
    <div v-else-if="!isWiki && !permission && permission === 0" class="noPower-div">
      <div class="noPower-box">
        <svg-icon icon-class="noPermission"></svg-icon>
        <div class="title-text">没有权限访问</div>
        <p class="tip-text">你可以向<span class="owner-text">{{ owner}}(所有者)</span>申请权限</p>
      </div>
    </div>
    <div v-else-if="isOtherWord" class='box'>
      <Header :chapterObj="chapterObj" :isWiki="true" :title="title" :parentName="parentName" :parentStr="parentStr" :updateTime="updateTime" :optionName="optionName" :isMy="isMy"></Header>
      <iframe :src="href"></iframe>
      <!-- <iframe src="http://10.10.40.222:8000/data/attachment/dzz/202204/07/140954ntxlfuszbusftzgm.mp4"></iframe> -->
    </div>
    <div v-else-if="!noData && !isLoading">
      <template v-if="isInIframe || (!isWiki && type==='read' && !isLoading) || (isWiki && kbPermission === 1 && permission === 3)">
        <show :chapterObj="chapterObj" :isWiki="isWiki" :isCollapse="isCollapse" :mdStr="mdStr" :chapterId="chapterId" :parentName="parentName" :updateTime="updateTime" :optionName="optionName" :parentStr="parentStr" :title="title" :isMy="isMy" :createWorkcode="createWorkcode" :createPerson="createPerson" @changeMD="changeMDS" @rebackMd="rebackMd"></show>
      </template>
      <template v-else-if="(!isWiki && type==='edit' && !isLoading) || (isWiki && (kbPermission === 2 || [1, 2].includes(this.permission)))">
        <edit :chapterObj="chapterObj" :isWiki="isWiki" :isCollapse="isCollapse" :mdStr="mdStr" :chapterId="chapterId" :parentName="parentName" :parentId="parentId" :parentStr="parentStr" :title="title" @updateTitle="updateTitle" :createWorkcode="createWorkcode" :createPerson="createPerson" @changeMD="changeMDS" @rebackMd="rebackMd"></edit>
      </template>
      <template v-else>
        <div class="noPower-div">
          <div class="noPower-box">
            <svg-icon icon-class="noPermission"></svg-icon>
            <div class="title-text">没有权限访问</div>
            <p class="tip-text">你可以向<span class="owner-text">{{ owner}}(所有者)</span>申请权限</p>
          </div>
        </div>
      </template>
    </div>
    <div v-if="isLoading">
      数据加载中...
    </div>
  </div>
</template>
<script>
import show from './components/show.vue'
import edit from './components/edit.vue'
import { chapterDetail, recordShare, getHavePermission } from '@/api/space'
import Header from '@/components/headerShow'
import { newFile } from '@/api/fileApi'

export default {
  components: { show, edit, Header },
  props: {
    isWiki: {
      type: Boolean,
      defalut: false
    },
    isCollapse: {
      type: Boolean,
      defalut: false
    },
    kbPermission: {
      type: Number,
      defalut: 0
    }
  },
  data() {
    return {
      encryptionStr: '',
      isLoading: false,
      type: '', // edit 新增和编辑，read 展示
      noData: false,
      mdStr: '', // 文章内容
      oldMd: '', // 处理评论取消
      chapterId: null,
      parentName: '', // 父级文件名
      parentId: null, // 父级ID
      parentStr: '', // 父级加密传，用来处理头部会跳
      title: '', // 文件名
      updateTime: '',
      optionName: '', // 修改人
      isMy: false, // 是否是我的文章
      createPerson: '', // 文章所属人
      createWorkcode: '',
      setting: {
        title: '文档管理',
        keywords: '',
        description: ''
      },
      permission: null,
      owner: '',
      isOtherWord: false,
      href: '',
      chapterObj: {
        linkToAll: 0,
        linkWrite: 0
      },
      isInIframe: false
    }
  },
  // provide() {
  //   return {
  //     aa: this.chapterObj // 提供祖先组件的实例
  //   }
  // },
  computed: {
    mdChange: function() {
      return this.$store.getters.mdChange
    },
    wikiMenuItem() {
      return this.$store.getters.wikiMenuItem
    }
    // needChange: function() {
    //   return this.$store.getters.needChange
    // }
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      async handler(to, from) {
        if (from && from.path === to.path) {
          return
        }
        this.encryptionStr = this.$route.params.pathMatch
        console.log('监听路由1：' + this.encryptionStr)
        if (this.encryptionStr === -1) {
          this.type = 'edit'
        } else {
          if (sessionStorage.getItem('urlInfo')) {
            if (!this.isWiki || this.kbPermission !== 2) {
              // 我的空间权限
              this.getPermission()
              // 知识库-可读 需合并文章的读写权限取并集
              // this.getData()
            } else {
              // 知识库可读可写权限
              console.log('获取文章详情------')
              this.getData()
            }
          }
        }
        if (this.wikiMenuItem && this.wikiMenuItem.dpath) {
          this.isOtherWord = true
          this.getUrlDpath()
        } else {
          this.isOtherWord = false
        }
      }
    },
    mdChange(val, oldVal) {
      if (val !== oldVal) {
        console.log('触发')
        this.$nextTick(() => {
          this.mdStr = ''
          this.mdStr = this.$store.getters.mdStr
          this.oldMd = this.$store.getters.mdStr
        })
        this.$forceUpdate()
      }
    }
  },
  created() {
    this.isInIframe = window.self !== window.top
    this.$EventBus.$on('updateDetail', () => {
      console.log('刷新详情')
    })
  },
  methods: {
    getUrlDpath() {
      const Base64 = require('js-base64').Base64
      const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
      if (this.wikiMenuItem.type === 'newMp4' || this.wikiMenuItem.type === 'other') {
        newFile({
          action: 'getFileDetail',
          code: urlCode,
          path: this.wikiMenuItem.dpath
        }).then((res) => {
          this.href = res.data.url
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.href = `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=onlyOfficeEdit&path=` + this.wikiMenuItem.dpath
        if ([1, 2].includes(this.permission)) {
          this.href += `&code=${urlCode}`
        }
      }
    },
    getPermission() {
      this.isLoading = true
      getHavePermission({
        dpIdStr: this.$store.getters.udId.replaceAll('-', ','),
        encryptionStr: this.encryptionStr
      }).then((res) => {
        this.permission = res.data.permission
        this.owner = res.data.username
        if (!this.isWiki && this.permission && this.permission !== 0) {
          this.getData()
          // 1可管理 2可编辑 3可阅读 0无权限
          this.type = this.permission === 2 || this.permission === 1 ? 'edit' : 'read'
        } else if (this.isWiki) {
          this.getData()
        } else {
          this.isLoading = false
        }
      }).catch(() => {
        this.isLoading = false
      })
    },
    changeMDS(str) {
      console.log('哈哈哈')
      this.$nextTick(() => {
        this.mdStr = str
      })
    },
    rebackMd() {
      this.mdStr = this.oldMd
    },
    getData() {
      this.isLoading = true
      chapterDetail({ encryptionStr: this.encryptionStr }).then((res) => {
        this.isLoading = false
        // 通过判断是否是自己的文章isMy，来区分是编辑还是查看
        if (res.code === '000000' && res.data) {
          this.noData = false
          this.isLoading = false
          // this.type = res.data.isMy ? 'edit' : 'read'
          this.mdStr = res.data.content || ''
          this.oldMd = res.data.content || ''
          this.chapterId = res.data.chapterId || null
          this.parentName = res.data.parentName || '我的空间'
          this.parentId = res.data.parentId || null
          this.parentStr = res.data.parentStr || ''
          this.title = res.data.title || ''
          this.updateTime = res.data.updateTime || res.data.createTime
          this.optionName = res.data.optionName || res.data.createPerson
          this.createPerson = res.data.createPerson || res.data.username
          this.createWorkcode = res.data.createWorkcode || res.data.workcode
          this.isMy = res.data.isMy
          if (res.data.isMy === false) {
            const record = {
              chapterId: res.data.chapterId,
              createWorkcode: res.data.createWorkcode,
              encryptionStr: res.data.encryptionStr,
              origin: res.data.origin,
            }
            this.setRecord(record)
          }
          this.setting.title = this.title
          this.setting.description = this.mdStr.substring(0, 80)
          this.chapterObj = Object.assign(this.chapterObj, {
            username: this.optionName ? this.optionName : this.createPerson,
            workcode: this.createWorkcode,
            linkToAll: res.data.linkToAll,
            linkWrite: res.data.linkWrite,
            id: this.chapterId,
            dpath: res.data.dpath,
            encryptionStr: res.data.encryptionStr,
            hasArticle: res.data.hasArticle,
            permission: this.permission
          })
          // 知识库的权限kbPermission需合并文章的读写权限取并集 
          // permission1可管理 2可编辑 3可阅读 0无权限（kbPermission 2编辑 1阅读）
          if (this.isWiki && this.kbPermission === 2) {
            this.permission = 1
            console.log(888, this.permission, this.kbPermission)
          }
        } else {
          this.noData = true
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        }
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      }).catch(() => {
        this.isLoading = false
      })
    },
    setRecord(params) {
      recordShare(params).then(res => {
        console.log(res, '插入结果，')
      }).catch()
    },
    updateTitle(title, str) {
      if (!(this.title && this.title !== '未命名文档')) {
        this.title = title
      }
      this.mdStr = str
      this.oldMd = str
    }
  },
  beforeDestroy() {
    this.$EventBus.$off('updateDetail')
  }
}
</script>
<style scoped lang="scss">
.box {
  height: 100%;
  width: 100%;
  overflow-y: scroll; //必须有这个，否则ios无法滚动。
  -webkit-overflow-scrolling:touch; // ios滚动卡顿的问题。
}

iframe {
  width:100%;
  height:calc(100% - 90px);
}
</style>
