<template>
  <div class="article-header">
    <div class="navigation-bar">
      <div class="navigation-bar-left" v-if="!isInIframe">
        <div class="bg-div">
          <el-popover
            placement="bottom-start"
            width="160"
            trigger="click">
            <div class="right-panel">
              <ul class="operate-list">
                <router-link :to="{path:'/drive/home'}">
                  <li class="operate-item">
                    <span class="icon">
                      <svg-icon icon-class="home"></svg-icon>
                    </span>
                    <span class="operate">主页</span>
                  </li>
                </router-link>
                <router-link :to="{path:'/drive/me'}">
                  <li class="operate-item">
                    <span class="icon">
                      <svg-icon icon-class="folder"></svg-icon>
                    </span>
                    <span class="operate">我的空间</span>
                  </li>
                </router-link>
                <router-link :to="{path:'/drive/shared'}">
                  <li class="operate-item">
                    <span class="icon">
                      <svg-icon icon-class="person"></svg-icon>
                    </span>
                    <span class="operate">与我分享</span>
                  </li>
                </router-link>
                <!-- <router-link :to="{path:'/wiki'}">
                  <li class="operate-item">
                    <span class="icon">
                      <svg-icon icon-class="knowledge"></svg-icon>
                    </span>
                    <span class="operate">知识库</span>
                  </li>
                </router-link> -->
                <router-link :to="{path:'/drive/favorites'}">
                  <li class="operate-item">
                    <span class="icon">
                      <svg-icon icon-class="collect"></svg-icon>
                    </span>
                    <span class="operate">收藏</span>
                  </li>
                </router-link>
                <router-link :to="{path:'/drive/trash'}">
                  <li class="operate-item">
                    <span class="icon">
                      <svg-icon icon-class="recycle"></svg-icon>
                    </span>
                    <span class="operate">回收站</span>
                  </li>
                </router-link>
              </ul>
            </div>
            <!-- <el-button slot="reference" type="primary" size="small">新建<i class="el-icon-arrow-down el-icon--right"></i></el-button> -->
            <svg-icon slot="reference" icon-class="left-icon"></svg-icon>
          </el-popover>
          <!-- <svg-icon icon-class="left-icon"></svg-icon> -->
        </div>
      </div>
      <div class="navigation-bar-content">
        <div class="navigation-bar-title">
          <div class="left-content">
            <div class="left-content-title">
              <span style="padding-left:4px;">{{title}}</span>
              <!-- <svg-icon icon-class="collect" style="font-size:16px;margin-left:6px;cursor:pointer"></svg-icon> -->
            </div>
            <div class="left-content-status">
              <span class="clearfix parentMenu" @click="backToFolider" v-if="!isInIframe || (parentStr && isMy)">
                <svg-icon icon-class="folder" class="fl"></svg-icon>
                <span class="fl">{{parentName}}</span>
              </span>
              <span class="divider" v-if="!isInIframe || (parentStr && isMy)"></span>
              <span class="">最近修改 {{optionName}} {{updateTime}}</span>
            </div>
          </div>
          <div class="right-content">
            <el-button style="margin-right: 20px" type="primary" size="small" @click="shareClick"><i class="el-icon-user"></i>分享</el-button>
            <!-- <i class="el-icon-more"></i>
            <i class="el-icon-search"></i>
            <i class="el-icon-plus"></i> -->
            <div class="right-content-operate clearfix">
              <!-- <el-link type="primary"><icon icon='icon="el-icon-link"'></icon>主要链接</el-link>
              <el-button type="text" size="medium" class="fl" icon="el-icon-link">复制链接</el-button>
              <span class="users fl">{{username}}</span> -->
              <el-popover
                placement="bottom"
                width="224"
                trigger="click">
                <div class="user-setting">
                  <div class="user-setting-top">
                    <el-image v-if="headImageUrl" class="userPhoto" :src="headImageUrl" title="个人信息" alt="加载失败" />
                    <div v-else class="userImg" title="个人信息" alt="加载失败">{{$store.getters.name.length > 2 ? $store.getters.name.substring(1,$store.getters.name.length) : $store.getters.name }}</div>
                    <span>{{$store.getters.name}}</span>
                    <p>{{$store.getters.udName}}</p>
                  </div>
                  <ul class="user-setting-bottom">
                    <li @click="editUser"><span>编辑用户信息</span></li>
                    <li @click="logout"><span>退出登录</span></li>
                  </ul>
                </div>
                <div slot="reference">
                  <el-image v-if="headImageUrl" class="userPhoto" :src="headImageUrl" title="个人信息" alt="加载失败" @error="error" />
                  <div v-else class="userImg" title="个人信息" alt="加载失败">{{$store.getters.name.length > 2 ? $store.getters.name.substring(1,$store.getters.name.length) : $store.getters.name }}</div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分享 弹窗 -->
    <share-dialog
      :dialogVisible="shareVisible"
      :chapterObj.sync="chapterObj"
      @closeShareDialog="closeShareDialog"
      @powerSuccess="powerSuccess">
    </share-dialog>
    <user ref="user" />
  </div>
</template>
<script>
import { getUser } from '@/api/user'
import shareDialog from '@/components/shareDialog/index'
import user from '@/views/user/index'

export default {
  components: { shareDialog, user },
  data() {
    return {
      shareVisible: false,
      isInIframe: false
    }
  },
  created() {
    this.isInIframe = window.self !== window.top || this.$route.path.indexOf('wiki/detail') > 0
    getUser({ workcode: this.$store.getters.workcode }).then(res => {
      if (res.data.length > 0) {
        this.$store.commit('SET_HEADiMAGE', res.data[0].headImgName)
      }
    }).catch(error => { console.log(error) })
  },
  computed: {
    username() {
      let name = this.$store.getters.name
      name = name.length > 2 ? name.slice(name.length - 2) : name
      return name
    },
    parent() {
      return this.parentName === '' ? (this.isMy ? '我的空间' : '') : this.parentName
    },
    headImageUrl() {
      return this.$store.getters.headImageUrl ? `${process.env.VUE_APP_BASE_API}/user/downloadHeadImg?fileName=` + this.$store.getters.headImageUrl + `&${this.code}` : null
    },
    code() {
      const urlInfo = JSON.parse(sessionStorage.getItem('urlInfo'))
      const code = 'token=' + urlInfo.token + '&hostIp=' + urlInfo.ip + '&systemCode=' + urlInfo.systemCode + '&sfcLang=en'
      return code
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    parentName: {
      type: String,
      default: ''
    },
    parentStr: {
      type: String,
      default: ''
    },
    updateTime: {
      type: String,
      default: ''
    },
    optionName: {
      type: String,
      default: ''
    },
    isMy: {
      type: Boolean,
      default: false
    },
    isWiki: {
      type: Boolean,
      default: false
    },
    chapterObj: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    backToFolider() {
      let pathStr = this.parentStr ? '/drive/folder/' + this.parentStr : '/drive/me'
      this.$router.push({
        // path: item.router + '/' + item.fileName
        path: pathStr
      })
    },
    logout() {
      this.$confirm('退出登录后，你将无法收到所有帐号的通知', '确认退出登录所有帐号吗？', {
        confirmButtonText: '退出',
        cancelButtonText: '返回',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut')
        // this.$router.push({ path: '/login' })
        window.close()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    editUser() {
      console.log(111)
      this.$refs.user.openDialog()
    },
    shareClick() {
      this.shareVisible = true
      console.log(3331, this.chapterObj)
    },
    closeShareDialog() {
      this.shareVisible = false
    },
    powerSuccess() {
      console.log('分享权限修改成功')
    },
    error() {
      this.$store.commit('SET_HEADiMAGE', '')
    }
  }
}
</script>
<style lang="scss" scoped>
.article-header {
  height: 60px;
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  .navigation-bar {
    width: 100%;
    border-bottom: 1px solid #dee0e3;
    display: flex;
    flex: 1;
    flex-direction: row;
    .navigation-bar-left {
      width: 50px;
      padding-left: 20px;
      display: flex;
      flex: 0 0 auto;
      position: relative;
      .bg-div {
        width: 25px;
        height: 26px;
        margin-top: 17px;
        padding-left: 4px;
        padding-top: 2px;
        border-radius: 4px;
        &:hover {
          background: #dee0e3;
          cursor: pointer;
        }
        .svg-icon {
          font-size: 22px !important;
        }
      }
    }
    .navigation-bar-content {
      width: calc(100% - 100px);
      margin-right: 16px;
      height: 46px;
      margin-top: 10px;
      flex: 1;
      .navigation-bar-title {
        user-select: none;
        position: relative;
        display: flex;
        flex-direction: row;
        .left-content {
          overflow: hidden;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          height: 46px;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          flex-direction: column;
          font-size: 14px;
          .left-content-title {
            display: flex;
            flex: 1;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            color: #1f2329;
          }
          .left-content-status {
            color: rgb(136, 134, 134);
            display: flex;
            flex: 1;
            width: 100%;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .parentMenu {
              .svg-icon {
                margin-right: 3px;
              }
              padding: 2px 4px;
              border-radius: 3px;
              &:hover {
                cursor: pointer;
                background: #dee0e3;
              }
            }
            .divider {
              margin: 0 8px;
              width: 1px;
              height: 18px;
              background: #dee0e3;
            }
          }
        }
        .right-content {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          height: 46px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .users {
            width: 32px;
            height: 32px;
            font-size: 12px;
            line-height: 32px;
            text-align: center;
            display: inline-block;
            margin-left: 10px;
            border-radius: 50%;
            background: #f4870a;
            color: #fff;
          }
          .userPhoto {
            float: left;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            font-size: 12px;
          }
          .userImg {
            width: 32px;
            height: 32px;
            background-color: #f4870a;
            color: #ffffff;
            font-size: 12px;
            border-radius: 50%;
            line-height: 32px;
            text-align: center;
          }
        }
      }
    }
  }
}
.user-setting {
  &-top {
    padding: 24px 8px 5px;
    text-align: center;
    border-bottom: 1px solid #dee0e3;
    .userImg {
      width: 40px;
      height: 40px;
      margin: 0 auto 10px;
      background-color: #f4870a;
      color: #ffffff;
      font-size: 12px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
    }
    .userPhoto {
      margin: 0 auto 10px;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 12px;
    }
    p {
      margin: 10px 0;
    }
  }
  &-bottom {
    list-style-type: none;
    padding: 0 5px;
    li {
      padding: 2px 0;
      border-bottom: 1px solid #dee0e3;
      &:last-child {
        border-bottom: none;
      }
      span {
        display: block;
        padding: 8px 10px;
        &:hover {
          background-color: #1f232914;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }
  }
}
.el-icon-more,
.el-icon-search,
.el-icon-plus {
  font-size: 19px;
  margin-left: 20px;
}
</style>
