<template>
  <div class="edit-box"
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <Header :chapterObj="chapterObj" :isWiki="true" :title="title" :parentName="parentName" :parentId="parentId" :parentStr="parentStr" :isUpdate="isUpdate"></Header>
    <aomaoEditor :title="title" :chapterObj="chapterObj" :mdStr="mdStr" :isReadonly="false" :chapterId="chapterId" @contentChange="submitUpdate"></aomaoEditor>
  </div>
</template>
<script>
import Header from '@/components/header'
import aomaoEditor from '@/components/AomaoEditor/Editor.vue'
import { updateContentSpace } from '@/api/space'
export default {
  components: {
    aomaoEditor,
    Header
  },
  data() {
    return {
      isLoading: false,
      isUpdate: false
    }
  },
  props: {
    isWiki: {
      type: Boolean,
      defalut: false
    },
    isCollapse: {
      type: Boolean,
      defalut: false
    },
    mdStr: {
      type: String,
      default: ''
    },
    chapterId: {
      type: Number,
      default: null
    },
    parentName: {
      type: String,
      default: '我的空间'
    },
    parentId: {
      type: Number,
      default: null
    },
    parentStr: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    createWorkcode: {
      type: String,
      default: ''
    },
    createPerson: {
      type: String,
      default: ''
    },
    chapterObj: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    
  },
  methods: {
    submitUpdate(contentStr) {
      console.log(9999, 'edit', this.commentIds)
      // const contentStr = this.$refs.myvditor.getContent()
      const title = document.querySelectorAll('h1,h2,h3,h4,h5,h6').length > 0 ? document.querySelectorAll('h1,h2,h3,h4,h5,h6')[0].innerText.replace(/\#*\s/ig, '') : '未命名文档'
      this.isUpdate = true
      updateContentSpace({
        chapterId: this.chapterId,
        title: title,
        content: contentStr,
        username: this.$store.getters.name
      }).then((res) => {
        if (res.code !== '000000') {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        } else {
          this.$emit('updateTitle', title, contentStr)
        }
        this.isUpdate = false
      }).catch(err => {
        console.log(err)
        this.isUpdate = false
      })
    },
  }
}
</script>
<style lang="scss">
.edit-box {
  height: calc(100vh);
  .operate-box {
    z-index: 999;
    position: absolute;
    cursor: pointer;
    .comment_on {
      display: none;
    }
    &:hover {
      .comment {
        display: none;
      }
      .comment_on {
        display: block;
      }
    }
  }
}
.selection-span {
  border-bottom: 2px solid #ffc60a ;
  display: inline-block !important;
  background-color: #faf1d1;
  .selection-span {
    border-bottom: none;
  }
}
.wiki-content-box {
  .fn-flex #vditor .vditor-outline {
    left: 281px
  }
}
</style>
