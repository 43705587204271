<template>
  <div class="read-box">
    <Header :chapterObj="chapterObj" :isWiki="true" :title="title" :parentName="parentName" :parentStr="parentStr" :updateTime="updateTime" :optionName="optionName" :isMy="isMy"></Header>
    <aomaoEditor :title="title" :chapterObj="chapterObj" :mdStr="mdStr" :chapterId="chapterId" :isReadonly="true" @contentChange="submitUpdate"></aomaoEditor>
  </div>
</template>
<script>
import Header from '@/components/headerShow'
import aomaoEditor from '@/components/AomaoEditor/Editor.vue'
import { updateContentSpace } from '@/api/space'
export default {
  components: { Header, aomaoEditor },
  data() {
    return {
    }
  },
  props: {
    isWiki: {
      type: Boolean,
      defalut: false
    },
    isCollapse: {
      type: Boolean,
      defalut: false
    },
    mdStr: {
      type: String,
      default: ''
    },
    chapterId: {
      type: Number,
      default: null
    },
    parentName: {
      type: String,
      default: ''
    },
    parentStr: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    updateTime: {
      type: String,
      default: ''
    },
    optionName: {
      type: String,
      default: ''
    },
    isMy: {
      type: Boolean,
      default: false
    },
    createWorkcode: {
      type: String,
      default: ''
    },
    createPerson: {
      type: String,
      default: ''
    },
    chapterObj: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    
  },
  methods: {
    submitUpdate(contentStr) {
      console.log(9999, 'edit', this.commentIds)
      // const contentStr = this.$refs.myvditor.getContent()
      const title = document.querySelectorAll('h1,h2,h3,h4,h5,h6').length > 0 ? document.querySelectorAll('h1,h2,h3,h4,h5,h6')[0].innerText.replace(/\#*\s/ig, '') : '未命名文档'
      this.isUpdate = true
      updateContentSpace({
        chapterId: this.chapterId,
        title: title,
        content: contentStr,
        username: this.$store.getters.name
      }).then((res) => {
        if (res.code !== '000000') {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        } else {
          this.$emit('updateTitle', title, contentStr)
        }
        this.isUpdate = false
      }).catch(err => {
        console.log(err)
        this.isUpdate = false
      })
    },
  }
}
</script>
<style lang="scss"></style>
